import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { isNil } from "ramda";
import { Result } from "true-myth";

export type KnownMimeTypes = "application/pdf" | "text/plain";

interface ValidationError {
    fieldName: string;
    message: string;
    messagePL?: string;
    rejectedValue: string;
}

type KnownHttp4xxStatusCodes =
    | 400
    | 401
    | 402
    | 403
    | 404
    | 405
    | 406
    | 407
    | 408
    | 409
    | 410
    | 411
    | 412
    | 413
    | 414
    | 415
    | 416
    | 417
    | 418
    | 421
    | 422
    | 423
    | 424
    | 425
    | 426
    | 428
    | 429
    | 431
    | 451;

export interface Http4xxErrorResponseBody {
    error: string;
    message: string;
    messagePL?: string;
    status: KnownHttp4xxStatusCodes;
    timestamp: string;
    validationErrors: ValidationError[] | null;
}

export const isHttp4xxErrorResponseBody = (body: any): body is Http4xxErrorResponseBody =>
    !isNil(body) &&
    typeof body.error === "string" &&
    typeof body.message === "string" &&
    typeof body.status === "number" &&
    body.status >= 400 &&
    body.status < 500;

export const isValidationErrorResponseBody = (body: Http4xxErrorResponseBody): body is ValidationErrorResponseBody =>
    body.status === 400 &&
    body.message === "Validation error" &&
    !!body.validationErrors &&
    Array.isArray(body.validationErrors);

export interface ValidationErrorResponseBody extends Http4xxErrorResponseBody {
    error: "Bad request";
    message: "Validation error";
    status: 400;
    timestamp: string;
    validationErrors: ValidationError[];
}

/**
 * TODO consider creating an own hierarchy of errors, instead of using AxiosError.
 * Different types of errors could have different properties and handling logic.
 */
export type AsyncResult<T> = Promise<Result<T, AxiosError>>;

// TODO This is not a very good typing because apiGet can be assigned to ApiGetWithResponseHeaders and vice versa. Changing this would require more extensive refactor though
export type ApiGet<T> = (url: string, config?: AxiosRequestConfig) => AsyncResult<T>;
export type ApiGetWithResponseHeaders<T> = (url: string, config?: AxiosRequestConfig) => AsyncResult<AxiosResponse<T>>;

export type ApiUpdate<T, R> = (url: string, data?: T, config?: AxiosRequestConfig) => AsyncResult<R>;
