import { map, mergeRight } from "ramda";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector, isDirty, isValid } from "redux-form";

import { Grid } from "@mui/material";
import { AsyncResult, apiGet } from "components/common/infractructure";
import { Currency } from "components/common/types";
import { TAX_RATE } from "components/common/ui-kit-business/InvoiceSummary/constants/costs";
import { doIfPresent, noop } from "components/common/utils";
import { parseNumber } from "components/common/utils/numberUtils";
import {
    GlobalState,
    InvoiceAttachment,
    MonthlySettlementPendingPaymentPosition,
    MonthlySettlementSummary,
    PENDING_PAYMENT_FORM,
    PendingPayment,
} from "modules/Invoices/views/MonthlySettlement/domain/types";
import { handleTabOrWindowClose } from "modules/Invoices/views/MonthlySettlement/service/eventHandlers";
import {
    getPendingPaymentPreviewAddress,
    updateCostPositionsUnitPrice,
    updateMainPositionsUnitPrice,
    updatePendingPaymentData,
    updatePendingPaymentPositionsWithFixedMonthlyRate,
} from "modules/Invoices/views/MonthlySettlement/service/summaryService";
import { BookedInvoiceWarning } from "modules/Invoices/views/MonthlySettlement/view/warnings/Warnings";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Maybe } from "true-myth";
import { useBoolean } from "usehooks-ts";
import { SummaryView } from "./SummaryView";
import { verifyIsEligibleToInvoice } from "modules/Invoices/views/MonthlySettlement/service/apiService";

export type PendingPaymentFormValues = Pick<PendingPayment, "invoiceNumber" | "invoiceIssueDate" | "contractorData"> &
    Pick<MonthlySettlementSummary, "invoiceAttachment">;

interface StateProps extends Omit<PendingPaymentFormValues, "invoiceAttachment"> {
    isPendingPaymentFormValid: boolean;
    isPendingPaymentFormDirty: boolean;
}

interface OwnProps {
    summary: MonthlySettlementSummary;
    setSummary: (summary: MonthlySettlementSummary) => void;
    rateValue: string;
    setRateValue: (rate: string) => void;
    getExchangeRateForDate: (currency: string, date: string) => AsyncResult<number>;
    uploadInvoice: (file: File) => AsyncResult<InvoiceAttachment>;
    savePendingPayment: (pendingPayment: PendingPayment) => AsyncResult<MonthlySettlementSummary>;
}

type Props = StateProps & OwnProps;

const getInvoiceUploadErrorMessage = (errorMsg: string): string => {
    return (
        {
            "Only single pages invoices are acceptable": "Plik faktury musi zawierać dokładnie jedną stronę!",
        }[errorMsg] ?? "Wystąpił niespodziewany błąd"
    );
};

const SummaryImpl = ({
    isPendingPaymentFormValid,
    isPendingPaymentFormDirty,
    contractorData,
    invoiceIssueDate,
    invoiceNumber,
    summary,
    setSummary,
    rateValue,
    setRateValue,
    getExchangeRateForDate,
    uploadInvoice,
    savePendingPayment,
}: Props) => {
    const navigate = useNavigate();
    const { value: isRedirectToPreview, setValue: setRedirectToPreview } = useBoolean(false);

    const [exchangeRate, setExchangeRate] = useState(summary.pendingPaymentData.rate);
    const { value: isLoading, setValue: setIsLoading } = useBoolean(false);

    const { isVatPayer, isMonthlyCharged } = contractorData;

    const handleInvoiceRemove = () => setSummary({ ...summary, invoiceAttachment: {} });

    const submitInvoice = async (invoice: File) => {
        if (!invoice.type.match("application/pdf")) {
            toast.error("Niepoprawny format pliku. Użyj pliku pdf.");
            return;
        }
        const res = await uploadInvoice(invoice);
        res.match({
            Ok: invoiceAttachment =>
                setSummary({
                    ...summary,
                    pendingPaymentData: {
                        ...summary.pendingPaymentData,
                        invoiceIssueDate,
                        invoiceNumber,
                        sourceDocumentId: `${invoiceAttachment.id}`,
                        contractorData: {
                            ...contractorData,
                        },
                    },
                    invoiceAttachment,
                }),
            Err: e => toast.error(getInvoiceUploadErrorMessage(e.response?.data ?? "")),
        });
    };

    const onUnitPriceChange = (unitPrice: string): void => {
        setRateValue(unitPrice);
        const taxRate = isVatPayer ? TAX_RATE : 0;
        parseNumber(unitPrice).match({
            Just: price => {
                const updatedPositions = contractorData.isMonthlyCharged
                    ? updatePendingPaymentPositionsWithFixedMonthlyRate(
                          taxRate,
                          price,
                          exchangeRate,
                          summary.pendingPaymentData.positions,
                      )
                    : map<MonthlySettlementPendingPaymentPosition, MonthlySettlementPendingPaymentPosition>(
                          updateMainPositionsUnitPrice(taxRate, price, exchangeRate),
                          summary.pendingPaymentData.positions,
                      );

                setSummary(
                    updatePendingPaymentData(
                        contractorData,
                        invoiceNumber,
                        invoiceIssueDate,
                        updatedPositions,
                        exchangeRate,
                        summary,
                    ),
                );
            },
            Nothing: noop,
        });
    };

    const loadExchangeRate = async (currency: Currency, date: string) => {
        const exchageRateResult = await getExchangeRateForDate(currency, date);
        setExchangeRate(exchageRateResult.unwrapOr(1));
    };

    useEffect(() => {
        const issueDateMilis = Date.parse(invoiceIssueDate);
        if (!Number.isNaN(issueDateMilis) && issueDateMilis <= new Date().getTime()) {
            loadExchangeRate(contractorData.currency, invoiceIssueDate);
        }
    }, [contractorData.currency, invoiceIssueDate]);

    useEffect(() => {
        if (isRedirectToPreview) {
            navigate(getPendingPaymentPreviewAddress(summary));
        }
        const beforeUnload = (e: BeforeUnloadEvent) => handleTabOrWindowClose(e, isPendingPaymentFormDirty);
        window.addEventListener("beforeunload", beforeUnload);
        return () => window.removeEventListener("beforeunload", beforeUnload);
    }, [isPendingPaymentFormDirty, isRedirectToPreview]);

    useEffect(() => {
        if (rateValue) {
            onUnitPriceChange(rateValue);
        }

        const taxRate = contractorData.isVatPayer ? TAX_RATE : 0;
        parseNumber(rateValue).match({
            Just: price => {
                const updatedPositions = contractorData.isMonthlyCharged
                    ? updatePendingPaymentPositionsWithFixedMonthlyRate(
                          taxRate,
                          price,
                          exchangeRate,
                          summary.pendingPaymentData.positions,
                      )
                    : map<MonthlySettlementPendingPaymentPosition, MonthlySettlementPendingPaymentPosition>(
                          updateMainPositionsUnitPrice(taxRate, price, exchangeRate),
                          summary.pendingPaymentData.positions.map(p =>
                              p.isMain ? { ...p, quantity: p.amountOfDays } : p,
                          ),
                      );

                setSummary(
                    updatePendingPaymentData(
                        contractorData,
                        invoiceNumber,
                        invoiceIssueDate,
                        updateCostPositionsUnitPrice(taxRate, exchangeRate, updatedPositions),
                        exchangeRate,
                        summary,
                    ),
                );
            },
            Nothing: noop,
        });
    }, [isVatPayer, isMonthlyCharged, rateValue, exchangeRate]);

    const onSubmitPendingPayment = async () => {
        const updatedPendingPayment: PendingPayment = {
            ...summary.pendingPaymentData,
            invoiceIssueDate,
            invoiceNumber,
            contractorData,
        };
        setIsLoading(true);
        const updatedSummary = await savePendingPayment(updatedPendingPayment);
        updatedSummary.match({
            Ok: () => setRedirectToPreview(true),
            Err: () => toast.error("Wystąpił błąd przy zapisie"),
        });
        doIfPresent(updatedSummary.toMaybe(), monthlySettlementSummary =>
            setSummary(mergeRight(summary, monthlySettlementSummary)),
        );
        setIsLoading(false);
    };

    return Maybe.of(summary.pendingPaymentData.status)
        .map(({ name }) => name === "BOOKED")
        .unwrapOr(false) ? (
        <Grid item xs={12}>
            <BookedInvoiceWarning />
        </Grid>
    ) : (
        <SummaryView
            handleInvoiceRemove={handleInvoiceRemove}
            onInvoiceSubmit={submitInvoice}
            monthlySettlementSummary={summary}
            contractorData={contractorData}
            onSubmitPendingPaymentForm={onSubmitPendingPayment}
            isPendingPaymentFormValid={isPendingPaymentFormValid}
            onUnitPriceChange={onUnitPriceChange}
            rateValue={rateValue}
            invoiceIssueDate={invoiceIssueDate}
            isLoading={isLoading}
            verifyIsEligibleToInvoice={verifyIsEligibleToInvoice(apiGet)}
        />
    );
};

const valueSelector = formValueSelector("pendingPaymentForm");
export const Summary = connect<StateProps, unknown, unknown, GlobalState>(state => ({
    isPendingPaymentFormValid: isValid(PENDING_PAYMENT_FORM)(state),
    isPendingPaymentFormDirty: isDirty(PENDING_PAYMENT_FORM)(state),
    contractorData: valueSelector(state, "contractorData"),
    invoiceIssueDate: valueSelector(state, "invoiceIssueDate"),
    invoiceNumber: valueSelector(state, "invoiceNumber"),
}))(SummaryImpl);
