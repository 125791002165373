import { ApiGet, ApiUpdate, AsyncResult } from "components/common/infractructure";
import { Nullable, YearMonth } from "components/common/types";
import {
    BookPaymentsRequest,
    BookPaymentsResponse,
    DocumentSummary,
    DocumentSummaryDTO,
    mapDocumentSummaryDTO,
    mapPendingPaymentDTO,
    MoneyTransferRequest,
    OperationType,
    PendingPayment,
    PendingPaymentChangeRequestParams,
    PendingPaymentDTO,
} from "modules/types/types";
import { ApiGetWithResponseHeaders, KnownMimeTypes } from "components/common/infractructure/types";
import { saveAs } from "file-saver";
import { AxiosResponse } from "axios";
import {
    convertDocumentDetailsDtoToDocumentDetails,
    CreateOtherTypeDocumentPaymentRequest,
    DocumentDetails,
    DocumentDetailsDto,
    DocumentOtherPaymentDetails,
    ExchangeRateDto,
    mapPaymentDTOToSearchResult,
    PaymentSearchResult,
    UpdateDocumentPaymentRequest,
    UpdateOtherTypeDocumentPaymentRequest,
} from "../types";
import { Employee, EmployeeDTO, mapEmployeeDTO } from "../../Projects/types";
import BigNumber from "bignumber.js";
import { PaymentDTO } from "../../Bank/types";

export const getPendingPayments =
    (apiGet: ApiGet<PendingPaymentDTO[]>) =>
    ({ year, month }: YearMonth): AsyncResult<PendingPayment[]> =>
        apiGet(`/accountingManagement/payments?year=${year}&month=${month}`).then(result =>
            result.map(dto => dto.map(mapPendingPaymentDTO)),
        );

export const sendMoneyTransferRequest =
    (apiPost: ApiUpdate<MoneyTransferRequest, string[]>) =>
    (moneyTransferRequest: MoneyTransferRequest): AsyncResult<string[]> =>
        apiPost("/accountingManagement/moneyTransfer", moneyTransferRequest);

export const sendBookInvoicesRequest =
    (apiPost: ApiUpdate<BookPaymentsRequest, BookPaymentsResponse>) =>
    (bookPaymentsRequest: BookPaymentsRequest): AsyncResult<BookPaymentsResponse> =>
        apiPost("/accountingManagement/book", bookPaymentsRequest);

export const updatePendingPayment =
    (apiPost: ApiUpdate<PendingPaymentChangeRequestParams, PendingPaymentDTO>) =>
    (pendingPaymentChangeRequest: PendingPaymentChangeRequestParams): AsyncResult<PendingPayment> =>
        apiPost("/accountingManagement/updatePayment", undefined, { params: pendingPaymentChangeRequest }).then(
            result => result.map(mapPendingPaymentDTO),
        );

export const getPendingPayment =
    (apiGet: ApiGet<PendingPaymentDTO>) =>
    (id: string): AsyncResult<PendingPayment> =>
        apiGet(`/accountingManagement/payments/${id}`).then(result => result.map(mapPendingPaymentDTO));

export interface InvoiceSpecifications {
    paymentIds: number[];
}
export const moveInvoicesToBooked =
    (apiPost: ApiUpdate<InvoiceSpecifications, void>) =>
    (paymentIds: number[]): AsyncResult<void> =>
        apiPost("accountingManagement/moveInvoicesToBooked", { paymentIds: paymentIds });

export const unlockInvoiceEdition =
    (apiPost: ApiUpdate<void, void>) =>
    (pendingPaymentId: number): AsyncResult<void> =>
        apiPost(`/accountingManagement/unlockInvoiceEdition?pendingPaymentId=${pendingPaymentId}`);

export const exportPendingPayment =
    (apiPost: ApiUpdate<void, PendingPaymentDTO>) =>
    (pendingPaymentChangeRequestParams: PendingPaymentChangeRequestParams): AsyncResult<PendingPayment> =>
        apiPost("/accountingManagement/moveInvoiceToTaxxo", undefined, {
            params: pendingPaymentChangeRequestParams,
        }).then(result => result.map(mapPendingPaymentDTO));

export const getDocuments =
    (apiGet: ApiGet<DocumentSummaryDTO[]>) =>
    ({ year, month }: YearMonth): AsyncResult<DocumentSummary[]> =>
        apiGet(`/document/documents?year=${year}&month=${month}`).then(result =>
            result.map(dto => dto.map(mapDocumentSummaryDTO)),
        );

export const getDocumentDetails =
    (apiGet: ApiGet<DocumentDetailsDto>) =>
    (taxxoId: string): AsyncResult<DocumentDetails> =>
        apiGet(`/document/details/${taxxoId}`).then(res =>
            res.map(documentDetailsDto => convertDocumentDetailsDtoToDocumentDetails(documentDetailsDto)),
        );

export const updateDocumentPayment =
    (apiPut: ApiUpdate<UpdateDocumentPaymentRequest, void>) =>
    (body: UpdateDocumentPaymentRequest): AsyncResult<void> =>
        apiPut(`/documentPayments/${body.documentPaymentId}`, body);

export const createDocumentOtherPayment =
    (apiPost: ApiUpdate<CreateOtherTypeDocumentPaymentRequest, DocumentOtherPaymentDetails>) =>
    (payload: CreateOtherTypeDocumentPaymentRequest): AsyncResult<DocumentOtherPaymentDetails> =>
        apiPost("/documentPayments/other", payload);

export const updateDocumentOtherPayment =
    (apiPut: ApiUpdate<UpdateOtherTypeDocumentPaymentRequest, void>) =>
    (body: UpdateOtherTypeDocumentPaymentRequest): AsyncResult<void> =>
        apiPut(`/documentPayments/other`, body);

export const getExchangeRate =
    (apiGet: ApiGet<ExchangeRateDto>) =>
    (date: string, baseCurrency: string, quoteCurrency: string): AsyncResult<BigNumber> =>
        apiGet(`/bank/exchangeRate?baseCurrency=${baseCurrency}&quoteCurrency=${quoteCurrency}&date=${date}`).then(
            result => result.map(exchangeRate => new BigNumber(exchangeRate.value)),
        );

export const getEmployees = (apiGet: ApiGet<EmployeeDTO[]>) => (): AsyncResult<Employee[]> =>
    apiGet(`/employee`).then(result => result.map(employees => employees.map(employee => mapEmployeeDTO(employee))));

export const getPaymentSearchResult =
    (apiGet: ApiGet<PaymentDTO[]>) =>
    (query: string): AsyncResult<PaymentSearchResult[]> =>
        apiGet("/payment/basic", {
            params: {
                query: query,
            },
        }).then(result => result.map(payments => payments.map(mapPaymentDTOToSearchResult)));

export interface DocumentSpecifications {
    accountingMonth: string;
    operationType: Nullable<OperationType>;
    ids: number[];
}

export const uploadDocumentsWithSpecifications =
    (apiPost: ApiUpdate<DocumentSpecifications, void>) =>
    (documentSpecifications: DocumentSpecifications): AsyncResult<void> =>
        apiPost("/document/uploadDocumentsWithSpecifications", documentSpecifications);

const getDocumentFileNameFromHeaders = (headers: AxiosResponse["headers"]): Nullable<string> => {
    const contentDisposition: string | undefined = headers["content-disposition"];
    const fileNameURLEncoded = contentDisposition?.split("filename=")[1].replaceAll('"', "");
    if (fileNameURLEncoded) {
        return decodeURI(fileNameURLEncoded);
    }
    return null;
};
export const downloadDocumentWithSpecification =
    (apiGet: ApiGetWithResponseHeaders<Blob>) => async (id: number, mimeType: KnownMimeTypes) =>
        (
            await apiGet(`document/documentFileWithSpecification?documentId=${id}`, {
                responseType: "blob",
            })
        ).match({
            Ok: ({ data, headers }) => {
                saveAs(new Blob([data], { type: mimeType }), getDocumentFileNameFromHeaders(headers) ?? id);
            },
            Err: error => Promise.reject(error),
        });
export const documentFileExportToSymfonia =
    (apiGet: ApiGetWithResponseHeaders<Blob>) => async (id: number, mimeType: KnownMimeTypes) =>
        (
            await apiGet(`document/documentExportFile?documentId=${id}`, {
                responseType: "blob",
            })
        ).match({
            Ok: ({ data, headers }) => {
                saveAs(new Blob([data], { type: mimeType }), getDocumentFileNameFromHeaders(headers) ?? id);
            },
            Err: error => Promise.reject(error),
        });

export const getTaxxoDocumentURL = (id: number) => `https://platforma2.taxxo.pl/TX20/Dokument.aspx?ID=${id}`;
