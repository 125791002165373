import {
    Box,
    Card,
    CardContent,
    Collapse,
    Divider,
    Grid,
    IconButton,
    IconButtonProps,
    Typography,
} from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import {
    ProjectDetails,
    Employee,
    Vacancy,
    filterByPendingCondition,
    formatEmployeeLabel,
    specializationAccountLabel,
    getSpecializationFromAccount,
} from "modules/Projects/types";
import React, { useState } from "react";
import { EmployeeAvatar } from "./EmployeeAvatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { getSpecializationTranslation, toDateString } from "components/common/types";
import { SxProps } from "@mui/system";
import * as Colors from "@mui/material/colors";
import { groupBy } from "./formUtils";
import { formatMaybeMoneyForView } from "components/common/utils/numberUtils";
import { Maybe } from "true-myth";
import { ArrowDropUp, AllInclusive, Edit } from "@mui/icons-material";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
    // eslint-disable-next-line
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

const vacancyRow = (
    project: ProjectDetails,
    v: Vacancy,
    hasEditRights: boolean,
    onEdit: (vacancy: Vacancy) => (event: React.KeyboardEvent | React.MouseEvent) => void,
) => {
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const toggleButton = (e: React.MouseEvent, show: boolean) => {
        e.preventDefault();
        setShowEdit(hasEditRights && show);
    };

    return (
        <TimelineItem
            key={`timeline-${v.id}`}
            onMouseEnter={e => toggleButton(e, true)}
            onMouseLeave={e => toggleButton(e, false)}
        >
            <TimelineOppositeContent
                sx={{
                    m: "0 0",
                }}
                align="right"
                variant="body2"
                color="text.secondary"
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    {v.end
                        .map(toDateString)
                        .map(d => <Box key={`${v.id}-vacancy-end-date`}>{d}</Box>)
                        .unwrapOr(
                            <Box>
                                <AllInclusive />
                            </Box>,
                        )}
                    <Box>
                        <ArrowDropUp />
                    </Box>
                    <Box>{toDateString(v.start)}</Box>
                </Box>
            </TimelineOppositeContent>
            <TimelineSeparator>
                {v.end.isJust() ? <TimelineDot color={v.isEditable ? "grey" : "primary"} /> : <></>}
                <TimelineConnector sx={{ backgroundColor: v.isEditable ? "" : "primary.main" }} />
                <TimelineDot color={v.isEditable ? "grey" : "primary"} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant="h6" component="span">
                            {v.role.name} {v.description.map(d => (d.length > 0 ? ` - ${d}` : "")).unwrapOr("")}
                        </Typography>
                        <IconButton
                            sx={{ visibility: showEdit ? "visible" : "hidden" }}
                            aria-label="edit"
                            onClick={onEdit(v)}
                        >
                            <Edit />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        {!project.isInternal && (
                            <Box display="flex" justifyContent="flex-end">
                                <Typography variant="h6" component="span">
                                    <b>{formatMaybeMoneyForView(Maybe.of(v.rate), project.currency)}</b>
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                <Grid container>
                    <Grid item xs={8}>
                        <Box display="flex">
                            <Typography>FTE {v.fullTimeEquivalent}%</Typography>
                            {v.account
                                .map(a => (
                                    <>
                                        <Divider
                                            orientation="vertical"
                                            variant={"middle"}
                                            flexItem
                                            sx={{ ml: "1rem", mr: "1rem" }}
                                        />
                                        <Typography>
                                            <b>{specializationAccountLabel[getSpecializationFromAccount(a)]}</b>
                                        </Typography>
                                        <Divider
                                            orientation="vertical"
                                            variant={"middle"}
                                            flexItem
                                            sx={{ ml: "1rem", mr: "1rem" }}
                                        />
                                        <Typography>{a}</Typography>
                                    </>
                                ))
                                .unwrapOr(<></>)}
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display="flex" justifyContent="flex-end">
                            {v.provision
                                .map(p =>
                                    p > 0 ? (
                                        <Typography key={`${v.id}-vacancy-provision`}>prowizja {p}%</Typography>
                                    ) : (
                                        <></>
                                    ),
                                )
                                .unwrapOr(<></>)}
                            {v.discount
                                .map(d =>
                                    d > 0 ? (
                                        <>
                                            <Divider
                                                orientation="vertical"
                                                variant={"middle"}
                                                flexItem
                                                sx={{ ml: "1rem", mr: "1rem" }}
                                            />
                                            <Typography>rabat {d}%</Typography>
                                        </>
                                    ) : (
                                        <></>
                                    ),
                                )
                                .unwrapOr(<></>)}
                        </Box>
                    </Grid>
                </Grid>
            </TimelineContent>
        </TimelineItem>
    );
};

interface Props {
    project: ProjectDetails;
    employee: Employee;
    vacancies: Vacancy[];
    hasEditRights: boolean;
    onEdit: (vacancy: Vacancy) => (event: React.KeyboardEvent | React.MouseEvent) => void;
    sx?: SxProps<Theme>;
}

export const VacancyCard: React.FC<Props> = ({ project, employee, vacancies, hasEditRights, onEdit, sx }) => {
    const now = new Date();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const vacanciesByActive = groupBy(vacancies, v => filterByPendingCondition(v.end, now, true));
    const activeVacancies = (vacanciesByActive.get(true) ?? []).sort((a, b) =>
        a.start.getDate() >= b.start.getDate() ? -1 : 1,
    );
    const archiveVacancies = (vacanciesByActive.get(false) ?? []).sort((a, b) =>
        a.start.getDate() >= b.start.getDate() ? -1 : 1,
    );
    const hasArchive = archiveVacancies.length > 0;

    return (
        <Card sx={{ ...sx }}>
            <CardContent>
                <Grid container item xs={12}>
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                display: "inline-flex",
                                alignContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <EmployeeAvatar sx={{ width: "64px", height: "64px" }} employee={employee} />
                            <Box>
                                <Typography variant={"h6"}>{formatEmployeeLabel(employee)}</Typography>
                                {employee.specialization
                                    .map(s => getSpecializationTranslation(s))
                                    .map(s => (
                                        <Typography
                                            key={`employee-specialization-${employee.id}`}
                                            variant={"subtitle1"}
                                        >
                                            {s} | {employee.level}
                                        </Typography>
                                    ))
                                    .unwrapOr(<Typography variant={"subtitle1"}>{employee.level}</Typography>)}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Timeline
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.2,
                                },
                                margin: 0,
                            }}
                        >
                            {activeVacancies.map(v => vacancyRow(project, v, hasEditRights, onEdit))}
                        </Timeline>
                    </Grid>
                </Grid>
            </CardContent>
            {hasArchive && (
                <>
                    <ExpandMore
                        sx={{
                            position: "absolute",
                            bottom: 16,
                            right: 16,
                        }}
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent sx={{ backgroundColor: Colors.grey["100"] }}>
                            <Grid container item xs={12}>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={9}>
                                    <Timeline
                                        sx={{
                                            [`& .${timelineOppositeContentClasses.root}`]: {
                                                flex: 0.2,
                                            },
                                            margin: 0,
                                        }}
                                    >
                                        {archiveVacancies.map(v => vacancyRow(project, v, hasEditRights, onEdit))}
                                    </Timeline>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>
                </>
            )}
        </Card>
    );
};
