import { DocumentSearch } from "modules/Bank/views/Transactions/TransactionsModal/DocumentSearch";
import { Column } from "@material-table/core";
import { Box } from "@mui/material";
import { DocumentSearchResult } from "modules/Bank/types";
import { useEffect, useState } from "react";
import { Maybe } from "true-myth";
import { isEmpty, sort } from "ramda";
import { LoadingState, toDateString } from "components/common/types";
import { DocumentSearchParams } from "modules/Bank/service/apiService";
import { AsyncResult } from "components/common/infractructure";
import { noop } from "components/common/utils";
import { localizationMaterialTable } from "components/common/localization/localization";
import toast from "react-hot-toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { boldHeaderStyle } from "components/common/ui-kit/components/MaterialTable/utils";
import TaskIcon from "@mui/icons-material/Task";
import { Table, TableActionIconButton } from "components/common/ui-kit/components/Table";
import { EmptyListInfo } from "../../../../../components/common/ui-kit/components/EmptyListInfo/EmptyListInfo";

interface State extends LoadingState {
    documents: DocumentSearchResult[];
}

interface Props {
    searchDocuments: (documentSearchParams: DocumentSearchParams) => AsyncResult<DocumentSearchResult[]>;
    addPaidDocument: (document: DocumentSearchResult) => void;
    setShowDialog: (value: boolean) => void;
}

const sortDocuments = sort<DocumentSearchResult>((a, b) => b.saleDate.getTime() - a.saleDate.getTime());

export const DocumentSelection = ({ searchDocuments, addPaidDocument, setShowDialog }: Props) => {
    const [searchQuery, setSearchQuery] = useState("");

    const handleAddClick = (document: DocumentSearchResult) => {
        addPaidDocument(document);
        setShowDialog(false);
    };

    const handleCopyId = (document: DocumentSearchResult) => {
        navigator.clipboard
            .writeText(document.documentBusinessId)
            .then(() => toast.success("Skopiowano BusinessID do schowka"))
            .catch(() => toast.error("Nie udało się skopiować BusinessID"));
    };

    const columns: Column<DocumentSearchResult>[] = [
        { field: "documentSymbol", title: "Nazwa faktury" },
        { field: "saleDate", title: "Data sprzedaży", render: ({ saleDate }) => toDateString(saleDate) },
        {
            field: "paidDate",
            title: "Data opłacenia",

            render: ({ paidDate }: DocumentSearchResult) => <>{paidDate.map(toDateString).unwrapOr("-")}</>,
        },
        {
            field: "dueDate",
            title: "Termin płatności",
            render: ({ dueDate }: DocumentSearchResult) => <>{dueDate.map(toDateString).unwrapOr("-")}</>,
        },
        { field: "companyName", title: "Kontrahent" },
        {
            field: "documentGrossAmount",
            title: "Kwota brutto",
            type: "numeric",
            align: "right",
            render: ({ documentGrossAmount }) => <>{documentGrossAmount.toFixed(2)}</>,
        },
        {
            field: "documentUnpaidAmount",
            title: "Kwota nieopłacona",
            type: "numeric",
            align: "right",
            render: ({ documentUnpaidAmount }) => <>{documentUnpaidAmount.toFixed(2)}</>,
        },
        { field: "currency", title: "Waluta" },
    ];

    const [state, setState] = useState<State>({
        documents: [],
        isLoading: false,
        error: Maybe.nothing<Error>(),
    });

    const { documents, error } = state;

    useEffect(() => {
        const fetchModalData = async () => {
            if (isEmpty(searchQuery)) {
                setState(state => ({ ...state, documents: [], isLoading: false }));
                return;
            }

            setState(state => ({ ...state, isLoading: true }));
            const documentsResults = await searchDocuments({ query: searchQuery });

            documentsResults.match({
                Ok: documents =>
                    setState(state => ({ ...state, documents: sortDocuments(documents), isLoading: false })),
                Err: err => setState(state => ({ ...state, error: Maybe.just(err), isLoading: false })),
            });
        };

        fetchModalData();
    }, [searchQuery]);

    error.match({
        Just: err => {
            // handled by the ErrorBoundary
            throw err;
        },
        Nothing: noop,
    });

    const table = (
        <Table
            columns={columns}
            actionsColumn={document => {
                return (
                    <>
                        <TableActionIconButton
                            tooltip="Skopiuj Business ID"
                            onClick={() => handleCopyId(document)}
                            Icon={ContentCopyIcon}
                            style={{ marginRight: "1rem" }}
                        />
                        <TableActionIconButton
                            tooltip="Wybierz"
                            Icon={TaskIcon}
                            onClick={() => handleAddClick(document)}
                        />
                    </>
                );
            }}
            data={documents}
            components={{
                Container: props => <Box {...props} elevation={0} />,
            }}
            options={{
                showTitle: false,
                search: false,
                grouping: false,
                selection: false,
                showTextRowsSelected: true,
                paging: documents.length > 6,
                pageSize: 6,
                idSynonym: "documentId",
                headerStyle: boldHeaderStyle(),
            }}
            localization={localizationMaterialTable}
        />
    );

    return (
        <>
            <DocumentSearch setSearchQuery={setSearchQuery} />

            {documents.length > 0 ? table : <EmptyListInfo />}
        </>
    );
};
