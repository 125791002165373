import { Maybe } from "true-myth";
import * as yup from "yup";

export type Nullable<T> = T | null;

// typing the yup schema
// how to: https://github.com/jquense/yup#ensuring-a-schema-matches-an-existing-type
// known issue: https://stackoverflow.com/questions/71500802/type-mytype-does-not-satisfy-the-constraint-objectshape-index-signature-for
export type YupObjectShape<T> = Record<keyof T, yup.AnySchema>;

export type AnyObject = Record<string, any>;

export interface LoadingState {
    isLoading: boolean;
    error: Maybe<Error>;
}

export interface NamedEnumeration<T> {
    name: T;
}

export const packNamedEnum = <T extends string>(value: T): NamedEnumeration<T> => ({ name: value });

export type Currency = string;
